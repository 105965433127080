function randomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

async function waitForHealthy(healthCheckUrl: string) {
  while (true) {
    const response = await fetch(healthCheckUrl).catch(() => {});
    if (response?.ok) {
      break;
    }
    // Wait a random 10-20s interval before trying again to avoid 'thundering herd'.
    await new Promise((resolve) =>
      setTimeout(resolve, randomInt(10_000, 20_000))
    );
  }
}

export async function fetchWithRetry(
  url: string,
  options: RequestInit
): Promise<Response> {
  const response = await fetch(url, options).catch(() => {});
  if (!response || !response?.ok) {
    await waitForHealthy("/api/health");
    // Wait a random 1-4s interval before trying again to avoid 'thundering herd'
    await new Promise((resolve) =>
      setTimeout(resolve, randomInt(1_000, 4_000))
    );
    return fetchWithRetry(url, options);
  }
  return response;
}
